<template>
  <div class="system-setting">
    <a-alert v-if="isShowAlert"
             class="mb-20 py-8 px-16 rounded-sm"
             style="border: 1px solid #ffe58f;"
             banner>
      <template #message>
        客户联系尚未配置，会影响会话存档功能。
        <router-link class="ml-12"
                     :to="{path: '/enterprise/systemSettingCustomerContact'}">
          去配置
        </router-link>
      </template>
    </a-alert>

    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
    <a-form ref="formRef"
            layout="inline"
            :model="form"
            :rules="rules"
            :validateTrigger="['submit', 'blur']"
            hideRequiredMark>

      <a-steps direction="vertical">
        <a-step status="wait">
          <template #title>
            管理员登录 <a-button type="link"
                      target="_blank"
                      href="https://work.weixin.qq.com/wework_admin/frame#index">
              企业微信后台
            </a-button>。
          </template>
        </a-step>
        <a-step title="购买并设置开启范围"
                description="依次点击：管理工具-会话内容存档，设置购买版本的开启范围。"></a-step>
        <a-step title="复制可信IP地址到企业微信后台">
          <template #description>
            可信IP地址：116.62.59.181 <span class="color-primary cursor-pointer ml1"
                  @click="copy('116.62.59.181')">复制</span>
          </template>
        </a-step>
        <a-step title="设置消息加密公钥">
          <template #description>
            1. 复制消息加密公钥，粘贴到
            <a-button class="text-12"
                      type="link"
                      target="_blank"
                      href="https://work.weixin.qq.com/wework_admin/frame#financial/corpEncryptData">
              企业微信-会话内容存档后台
            </a-button>
            -消息加密公钥，为您的存档内容进行加密

            <div class="public-key__container">
              <p class="color-primary cursor-pointer mb2"
                 @click="copy(publicKey)">复制</p>
              <div class="gray text-12 ml1"
                   :style="{width: '465px'}">
                <p>-----BEGIN PUBLIC KEY-----</p>
                <p>{{`MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAl8KqXrFPr4LmI3nTU0YX
zNByDb790Nf/j9403YgLrvNFjNjhYSgrE8nTFsWKuZO7shbfHZDwKSM0yV/dX5On
ZRJsIzMsAwp6ZHtHqAIaVCz4gWBlhV4qx8nCdUihfnNzn3Nhguehm8VeKTIpV3Xm
qit6Tm1H9qNevFpbqPfEP4mPvB3AI1dwn+/aXLxAOa22U36/e55Y1osNy6/ka9d/
zoKq7UnVQ8p4ymvF+IW8gW5XVlvlBQrE9crQQFvx76vBHYP+pMzEf6kegTmyprJA
HqxwcjgbjWhtoExp5zd/bPd93mdppfg7N3H/uzXJv61QkG0q/5cf4juFZ4d4hvuc
PwIDAQAB`}}</p>
                <p>-----END PUBLIC KEY-----</p>
              </div>
            </div>
            <div :style="{display: 'flex', alignItems: 'center'}">
              2. 消息加密公钥保存后，请输入保存后的版本号
              <a-form-item class="mh12"
                           name="version">
                <a-input v-model:value.number="form.version"
                         class="version-input"
                         placeholder="例如01" />
              </a-form-item>
              (请与企微后台保持一致)
            </div>

          </template>
        </a-step>
        <a-step>
          <template #title>
            <p :style="{marginBottom: '8px'}">将Secret复制并返回EduSoho销客助手后台粘贴</p>
            <a-form-item label="Secret"
                         name="secret">
              <a-input v-model:value="form.secret"
                       :style="{width: '320px'}"
                       placeholder="Secret" />
            </a-form-item>

          </template>
        </a-step>

      </a-steps>
    </a-form>
    <div class="save-btn">
      <a-button class="btn"
                type="primary"
                centered
                :loading="btnLoading"
                @click="onSubmit">
        保存
      </a-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive } from "vue";
import { message, Steps, Alert } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { copy } from "@/global";

import RouterHeader from "@/components/RouterHeader";

import wechatSettingApi from "@/service/api/wechatSetting";
import corpSettingApi from "@/service/api/corpSetting";

export default defineComponent({
  name: "ChatRecordSetting",

  components: {
    RouterHeader,
    ASteps: Steps,
    AStep: Steps.Step,
    [Alert.name]: Alert,
  },

  setup() {
    const routerHeaderInfo = [
      {
        path: "/enterprise/systemSetting",
        name: "系统设置",
      },
      { name: "会话存档" },
    ];

    const formRef = ref();
    const form = reactive({
      secret: undefined,
      version: undefined,
    });

    const rules = {
      secret: [
        {
          required: true,
          message: "请输入secret",
          whiteSpace: true,
        },
      ],
      version: [
        {
          required: true,
          type: "number",
          message: "请输入版本号",
          whiteSpace: true,
        },
      ],
    };

    const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAl8KqXrFPr4LmI3nTU0YX
zNByDb790Nf/j9403YgLrvNFjNjhYSgrE8nTFsWKuZO7shbfHZDwKSM0yV/dX5On
ZRJsIzMsAwp6ZHtHqAIaVCz4gWBlhV4qx8nCdUihfnNzn3Nhguehm8VeKTIpV3Xm
qit6Tm1H9qNevFpbqPfEP4mPvB3AI1dwn+/aXLxAOa22U36/e55Y1osNy6/ka9d/
zoKq7UnVQ8p4ymvF+IW8gW5XVlvlBQrE9crQQFvx76vBHYP+pMzEf6kegTmyprJA
HqxwcjgbjWhtoExp5zd/bPd93mdppfg7N3H/uzXJv61QkG0q/5cf4juFZ4d4hvuc
PwIDAQAB
-----END PUBLIC KEY-----`;

    async function getChatRecordConf() {
      await wechatSettingApi.getChatRecordConf().then((res) => {
        _.assign(form, res);
      });
    }

    const btnLoading = ref(false);

    const route = new useRoute();
    const router = new useRouter();

    const isShowAlert = ref(false);

    async function getConfigurations() {
      const config = await corpSettingApi.getConfigurations();
      isShowAlert.value = config.setClientSecret.completePercent === 0;
    }

    getConfigurations();

    function onSubmit() {
      btnLoading.value = true;
      formRef.value
        .validate()
        .then(async () => {
          await wechatSettingApi.saveChatRecordConf(form);
          message.success("保存成功");
          if (route.query.isBack) {
            router.push({ name: "chatRecord_index" });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      btnLoading.value = false;
    }

    getChatRecordConf();

    return {
      routerHeaderInfo,
      formRef,
      form,
      rules,
      publicKey,
      copy,
      btnLoading,
      isShowAlert,
      onSubmit,
    };
  },
});
</script>
<style lang='less' scoped>
.system-setting .ant-steps-item-wait {
  min-height: 72px;
}

.public-key__container {
  padding: 12px 16px;
  margin: 12px 0;
  width: 640px;
  background-color: @bg-normal;
}

.version-input {
  width: 160px;
  height: 32px;
}

.save-btn {
  margin: 0 auto;
  margin-top: 80px;
}

:deep(.ant-alert-icon) {
  margin-right: 12px;
}
</style>
